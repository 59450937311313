.tarjetaFase .porcentaje{
	font-size: 10px;
    color: #212529;
    background-color: white;
    border-radius: 5px;
    width: 6%;
    border: 1px solid black;
}

.tarjetaFase .imagenTarjeta{
    height: 200px;
   }

.tarjetaFase .sinImagenTarjeta{
    height: 200px;
    object-fit: contain;
   }