@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

.botoneraUsuarios{
	border-bottom: 2px #21252920 solid;
}

.btnUsuarios{
	width: 15%;
}

.btnDelete{
	background-image: url('http://admin.garteel.com/img/deleteIcon.png');
	background-position:center;
	background-repeat: no-repeat;
	background-size: 70%;
	height: 30px;
	width: 35px;
}

.btnEdit{
	background-image: url('http://admin.garteel.com/img/editIcon.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 70%;
	height: 30px;
	width: 35px;
}

.btnMore{
	background-image: url('http://admin.garteel.com/img/moreIcon.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 70%;
	height: 30px;
	width: 35px;
}

.btnToggle{
	background-image: url('http://admin.garteel.com/img/toggleIcon.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.btnMore{
	background-image: url('http://admin.garteel.com/img/moreIcon.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	height: 30px;
	width: 35px;
}

.tablaAdministracion{
	font-size: small;
}

.thead{
	background-color: #2EBF70;
	color: white;
}

th{
	font-weight:600;
}

table thead tr th:first-child {
	border-radius: 10px 0 0 0;
}

table thead tr th:last-child {
	border-radius: 0 10px 0 0;
}

table tbody tr:last-child td:first-child {
	border-radius: 0 0 0 10px;
}

table tbody tr:last-child td:last-child {
	border-radius: 0 0 10px 0;
}

table tbody tr:last-child th:first-child {
	border-radius: 0 0 0 10px;
}

table tbody tr:last-child th:last-child {
	border-radius: 0 0 10px 0;
}

td, th {
	border-bottom: 0px;
}

.table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n+1) > th {
	background-color: #54535321;
 }

 .btn-success{
     background-color: #2EBF70 !important;
     border-color: #2EBF70 !important;
 }

 .labelRegistro{
	background-color: #2ebf6f14;
	border: 1px #56565620 solid;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding-left: 5px;
	padding-right: 5px;
}

.form-control:focus {
	border-color: #2EBF70;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(46, 191, 112, 0.6);
  }

.uploadPictureContainer .deleteImage{
	background-color: red !important;
}

.fileContainer{
	box-shadow: none !important;
}
  