.tarjetaProyecto .imagenTarjeta{
    height: 300px;
   }
   
.tarjetaProyecto .MuiCardActionArea-root:hover{
    color: #19643b;
}

.tarjetaProyecto .porcentaje{
	font-size: 10px;
    color: #7C9A92;
    background-color: white;
    border-radius: 5px;
    width: 10%;
}