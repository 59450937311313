.editor{
    border: 2px solid rgb(212, 212, 212);
    border-radius: 5px;
}

.editorToolBar{
    border-bottom: 1px solid rgb(232, 232, 232);
    border-left: none;
    border-right: none;
    border-top: none;
}