.tarjetaPropiedad .selloGarteel{
    position:absolute;
    margin-top: 10px;
    right: 9px;
}

.tarjetaPropiedad .MuiCardActionArea-root:hover{
	color: #2EBF70;
}

.imagenTarjeta{
    height: 400px;
}